import { combineReducers } from "redux";
import admins from "./admins/reducers";
import Auth from "./auth/reducers";
import blogArticles from "./blogArticles/reducers";
import Layout from "./layout/reducers";
import lycees from "./lycees/reducers";
import professionCategories from "./professionCategories/reducers";
import professions from "./professions/reducers";
import user from "./users/reducers";
import videos from "./videos/reducers";
import froms from "./from/reducers";

export default combineReducers({
  Layout,
  Auth,
  user,
  professionCategories,
  professions,
  lycees,
  videos,
  blogArticles,
  admins,
  froms
});
