import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// AUTH COMPONENTS
const Login = React.lazy(() => import('../pages/auth/Login'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const LockScreen = React.lazy(() => import('../pages/auth/LockScreen'));
// AUTH COMPONENTS

// PRIVATE PAGES COMPONENTS
const AppDashboard = React.lazy(() => import('../pages/dashboard/App'));
const ProfessionCategories = React.lazy(() => import("../pages/professionCategories"));
const Professions = React.lazy(() => import("../pages/professions"));
const Lycees = React.lazy(() => import("../pages/lycees"));
const Videos = React.lazy(() => import("../pages/videos"));
const Blog = React.lazy(() => import("../pages/blogArtilces"));
const Users = React.lazy(() => import("../pages/users"));
const Admins = React.lazy(() => import("../pages/admins"));
const MyAccount = React.lazy(() => import("../pages/auth/MyAccount"));
const Forms = React.lazy(() => import("../pages/from"));
// PRIVATE PAGES COMPONENTS

const Error404 = React.lazy(() => import('../pages/error/Error404'));
const Error500 = React.lazy(() => import('../pages/error/Error500'));
const CGU = React.lazy(() => import('../pages/conditions/CGU'));
const PasswordForgoten = React.lazy(() => import('../pages/pswd/PasswordForgoten'));
const ResetPassword = React.lazy(() => import('../pages/pswd/ResetPassword'));

export interface RoutesProps {
    path: RouteProps['path'];
    name?: string;
    component?: RouteProps['component'];
    route?: any;
    exact?: RouteProps['exact'];
    icon?: string;
    header?: string;
    roles?: string[];
    children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
    path: '/',
    exact: true,
    component: () => <Root />,
    route: Route,
};

// auth
const authRoutes: RoutesProps[] = [
    {
        path: '/auth/login',
        name: 'Login',
        component: Login,
        route: Route,
    },
    {
        path: '/auth/register',
        name: 'Register',
        component: Register,
        route: Route,
    },
    {
        path: '/auth/confirm',
        name: 'Confirm',
        component: Confirm,
        route: Route,
    },
    {
        path: '/auth/lock-screen',
        name: 'Lock Screen',
        component: LockScreen,
        route: Route,
    },
    {
        path: '/auth/logout',
        name: 'Logout',
        component: Logout,
        route: Route,
    },
];

// dashboards
const dashboardRoutes: RoutesProps = {
    path: '/',
    name: 'Dashboards',
    icon: 'airplay',
    header: 'Navigation',
    children: [
        {
            path: "/dashboard",
            component: AppDashboard,
            name: "App",
            route: PrivateRoute,
        },
        {
            path: "/profession-categories",
            name: "apps-profession-categories",
            route: PrivateRoute,
            component: ProfessionCategories,
        },
        {
            path: "/professions",
            name: "apps-professions",
            route: PrivateRoute,
            component: Professions,
        },
        {
            path: "/lycees",
            name: "apps-lycees",
            route: PrivateRoute,
            component: Lycees,
        },
        {
            path: "/videos",
            name: "apps-videos",
            route: PrivateRoute,
            component: Videos,
        },
        
        {
            path: "/blog",
            name: "apps-blogs",
            route: PrivateRoute,
            component: Blog,
        },
        {
            path: "/utilisateurs",
            name: "apps-users",
            route: PrivateRoute,
            component: Users,
        },
        {
            path: "/administrateurs",
            name: "apps-admins",
            route: PrivateRoute,
            component: Admins,
        },
        {
            path: '/mon-compte',
            name: 'Logout',
            component: MyAccount,
            route: Route,
        },
        {
            path: "/froms",
            name: "apps-froms",
            route: PrivateRoute,
            component: Forms,
        },
    ],
};

// public routes
const otherPublicRoutes: RoutesProps[] = [
    {
        path: '/error-404',
        name: 'Error - 404',
        component: Error404,
        route: Route,
    },
    {
        path: '/error-500',
        name: 'Error - 500',
        component: Error500,
        route: Route,
    },
    {
        path: '/conditions-generales-d-utilisation',
        name: 'CGU',
        component: CGU,
        route: Route,
    },    {
        path: '/conditions-generales-d-utilisation',
        name: 'CGU',
        component: CGU,
        route: Route,
    },    
    {
        path: '/change-password',
        name: 'ResetPassword',
        component: ResetPassword,
        route: Route,
    },  
    {
        path: '/reinitialisation-mot-de-passe',
        name: 'PasswordForgoten',
        component: PasswordForgoten,
        route: Route,
    },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
    let flatRoutes: RoutesProps[] = [];

    routes = routes || [];
    routes.forEach((item: RoutesProps) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const authProtectedRoutes = [rootRoute, dashboardRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
