import { From } from "../../types";

export enum FROM_ACTIONS {
    FROM_LIST = "FROM_LIST",
    FROM_ERROR = "FROM_ERROR",
    FROM_MODAL = "FROM_MODAL",
    FROM_LOADING = "FROM_LOADING",
    FROM_MUTATION_LOADING = "FROM_MUTATION_LOADING",
}

export interface FromState {
    froms: From[];
    showModal: boolean;
    isLoading: boolean;
    isMutationLoading: boolean;
    error: { [x: string]: string } | string | null;
}

const initialState: FromState = {
    froms: [],
    showModal: false,
    isLoading: false,
    isMutationLoading: false,
    error: null,
};

export interface FromActionType {
    type: keyof typeof FROM_ACTIONS;
    payload: any;
}

const reducer = (
    state: FromState = initialState,
    action: FromActionType
): FromState => {
    switch (action.type) {
        case FROM_ACTIONS.FROM_LIST:
            return {
                ...state,
                froms: action.payload,
                isLoading: false,
            };

        case FROM_ACTIONS.FROM_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };

        case FROM_ACTIONS.FROM_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        case FROM_ACTIONS.FROM_MODAL:
            return {
                ...state,
                showModal: action.payload,
            };

        case FROM_ACTIONS.FROM_MUTATION_LOADING:
            return {
                ...state,
                isMutationLoading: action.payload,
            };

        default:
            return {
                ...state,
            };
    }
};

export default reducer;
